import axios from 'axios';
import FormData from 'form-data';
import getCookie from './utils/getCookie';

const retrieveSavedApplicationService = () => {
  var data = new FormData();

  try {
    let tokenData = getCookie('tokenData');
    tokenData = JSON.parse(tokenData);
    const access_token = tokenData && tokenData.access_token;
    const user = tokenData && tokenData.user;
    const email_id = user.email_id;
    if (!access_token) {
      alert('your are not authorized and redirecting to Home page.');
      window.location.pathname = '/';
    }

    data.append('email_id', email_id);

    var config = {
      method: 'get',
      url: ' http://204.236.192.144:49159/api/account/get-applications',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };

    return axios(config)
      .then(function (response) {
        return Promise.resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        return Promise.reject(error);
      });
  } catch {
    window.location.pathname = '/error';
  }
};

export default retrieveSavedApplicationService;
