import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from 'components/Container';
import Footer from 'layouts/Main/components/Footer';
import retrieveSavedApplicationService from 'views/NotFoundCover/retrieveSavedApplicationService';

const mock = [
  {
    media: 'https://www.mavinlearning.com/wp-content/uploads/2017/12/Online_Employment_Application_Guide_New_User_Image_021_493505_7.jpg',
    href: '',
    companyLogo: 'Submited Appplication',
    description: 'Submited Appplication',
    dbField: 'SUBMITTED',
  },
  {
    media: '',
    href: '',
    companyLogo: 'Draft',
    description: 'Draft',
    dbField: 'DRAFT',
  },
];

const MyApplication = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const [applicationType, setApplicationType] = useState('');
  const [applicationList, setApplicationList] = useState([]);

  useEffect(() => {
    retrieveSavedApplicationService().then((res) => {
      console.log('data', res);
      setApplicationList(res);
    });
  }, []);

  const findSection = (rec) => {
    if (rec === 'Pre-KG') {
      return 'prekg';
    } else if (rec === 'Mont') {
      return 'prekg';
    } else if (rec === 'classGrade11') {
      return 'classGrade11';
    } else {
      return '11';
    }
  };

  const handleApplications = (item) => {
    console.log('handleApplications', item);
    setApplicationType(item);
  };

  const applicationTypelist = applicationList.filter(
    (rec) => rec.status === applicationType,
  );

  const handleNavigation = (row) => {
    if (
      globalThis &&
      globalThis.window &&
      globalThis.window.location &&
      globalThis.window.location.pathname
    ) {
      globalThis.window.location = new URL(
        window.location.origin +
          `/not-found-cover?section=${findSection(row.class1)}&applicationId=${
            row.id
          }`,
      );
    }
  };

  return (
    <>
    <div style={{ height: 40, backgroundColor: '#377dff', padding: '10px' }}>
    <Typography
    style={{ float: 'left' }}
    variant={'subtitle2'}
    color={'white'}
  >NPS YPR</Typography>
    <a style={{ float: 'right' }} href="/online-registration-email-login" >
      <img 
      height={20}
      width={20}
      color={'white'}
      src='https://cdn-icons-png.flaticon.com/512/25/25376.png'/>
    </a>
  </div>
 
    <Box>
      {applicationType ? (
        <div
          style={{
            marginTop: 100,
            marginLeft: 100,
            borderRadius: '2px solid',
            padding: 10,
          }}
        >
          <h2> We are showing {applicationType} Applications</h2>
          <div style={{ marginTop: 20 }}>
            {applicationTypelist && applicationTypelist.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                    <TableRow>
                      <TableCell>Application ID</TableCell>
                      <TableCell align="right">fullName</TableCell>
                      <TableCell align="right">Class</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Date of Birth</TableCell>
                      <TableCell align="right">Appliation Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {applicationTypelist.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {applicationType !== 'SUBMITTED' ? (
                            <a
                              style={{
                                textDecoration: 'underline',
                                color: 'blue',
                              }}
                              onClick={() => handleNavigation(row)}
                            >
                              {row.id}
                            </a>
                          ) : (
                            row.id
                          )}
                        </TableCell>
                        <TableCell align="right">{row.fullName}</TableCell>
                        <TableCell align="right">{row.class1}</TableCell>
                        <TableCell align="right">{row.email_id}</TableCell>
                        <TableCell align="right">{row.dateOfBirth}</TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              'No Applications found'
            )}
          </div>
        </div>
      ) : (
        <Grid container spacing={4} justifyContent="center">
          {mock.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                component={'a'}
                //  href={''}
                display={'block'}
                width={1}
                height={1}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={1}
                  borderRadius={2}
                  display={'flex'}
                  flexDirection={'column'}
                >
                  <CardMedia
                    image={item.media}
                    title={item.title}
                    sx={{
                      height: 180,
                    }}
                  />
                  <Box component={CardContent}>
                    <Box maxWidth={100} marginY={2}>
                      <Box
                        component="text"
                        height={1}
                        width={1}
                        src={item.companyLogo}
                        alt="..."
                        sx={{
                          filter: mode === 'dark' ? 'contrast(0)' : 'none',
                        }}
                      />
                    </Box>
                    <Typography
                      align={'left'}
                      variant={'h4'}
                      color="textSecondary"
                    >
                      {item.description}
                    </Typography>
                  </Box>
                  <Box flexGrow={1} />
                  <Box component={CardActions} justifyContent={'flex-start'}>
                    <Button
                      size="large"
                      onClick={() => handleApplications(item.dbField)}
                      endIcon={
                        <svg
                          width={16}
                          height={16}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </svg>
                      }
                    >
                      Click here
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
    <Container maxWidth={0.9} marginTop={-6}>
        <Footer />
      </Container>
    </>
  );
};

export default MyApplication;
